import axios from "axios";
import store from "@/store";
import router from "@/router";
import {Buffer} from "buffer";

class SlideLogic {
    static instance = null;
    static createInstance() {
        var object = new SlideLogic();
        return object;
    }

    static getInstance() {
        if (!SlideLogic.instance) {
            SlideLogic.instance = SlideLogic.createInstance();
        }
        return SlideLogic.instance;
    }

    constructor() {
    }

    async listar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/carrouselslide/listar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[SlideLogic] listar()::ERROR >>> "+error);
        }
    }

    async crear(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('fileImagen', objRequest.fileImagen);
            formData.append('texto', objRequest.texto);

            var urlRequest = ENV_APIPATH+"/carrouselslide/crear";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[SlideLogic] crear()::ERROR >>> "+error);
        }
    }

    async editar(objServicio){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var formData = new FormData();
            formData.append('fileImagen', objServicio.fileImagen);
            formData.append('id', objServicio.id);
            formData.append('texto', objServicio.texto);

            var urlRequest = ENV_APIPATH+"/carrouselslide/editar";
            var httpResponse = await HTTPHelper.httpPOST(urlRequest,formData,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[SlideLogic] editar()::ERROR >>> "+error);
        }
    }

    async detalle(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/carrouselslide/detalle";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[SlideLogic] detalle()::ERROR >>> "+error);
        }
    }

    async borrar(objRequest){
        try {
            // Dynamic Imports
            var {default: HTTPHelper} = await import('@/helpers/http.helper');
            var {default: CommonHelper} = await import('@/helpers/common.helper');

            var ENV_APIPATH = process.env.APIPATH;
            var ENV_APIDEBUG = CommonHelper.parseBool(process.env.APIDEBUG);

            var currentRoute = router.currentRoute;
            var API_ACCESSTYPE = currentRoute.meta.apiAccessType;

            var urlRequest = ENV_APIPATH+"/carrouselslide/borrar";

            var httpResponse = await HTTPHelper.httpPOST(urlRequest,objRequest,API_ACCESSTYPE,ENV_APIDEBUG);

            return httpResponse;

        } catch (error) {
            throw Error("[SlideLogic] borrar()::ERROR >>> "+error);
        }
    }
}

const instance = SlideLogic.getInstance();
export default instance;